import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import { useCallback, useEffect, useRef, useState } from 'react';

import type { TransientDocumentData } from '../types';
import { isMobile } from '../utils/environment';
import makeLogger from '../utils/makeLogger';
import useDebounce from '../utils/useDebounce';
import createInitialTransientDocumentData from './createInitialTransientDocumentData';
import { type ISearchResult, documentSearchEngine } from './documentSearchEngine';
import { CancelStateUpdate, globalState, updateState } from './models';
import { useContentIndexingMessage } from './useContentIndexingMessage';
import useLiveValueRef from './utils/useLiveValueRef';

const logger = makeLogger(__filename);

const SEARCH_QUERY_EXECUTION_DEBOUNCE = 250;
export const SEARCH_DEFAULT_PAGE_SIZE = isMobile ? 10 : 100;

/*
 * A convenience hook to allow populating search results based on user input.
 * This hooks provides state and methods to update the query + return results.
 */
export function useDocumentSearch(initialSearchQuery: string): {
  searchResult: ISearchResult | undefined;
  searchQuery: string;
  executedSearchQuery: string;
  isLoadingSearchResults: boolean;
  setSearchQuery: (value: ((prevState: string) => string) | string) => void;
  loadMoreResultRows: () => Promise<void>;
  contentIndexingMessage: string | undefined;
} {
  const isOnline = globalState(useCallback((state) => state.isOnline, []));
  // query
  const [searchQuery, setSearchQuery] = useState(initialSearchQuery);
  const debouncedSearchQuery = useDebounce(searchQuery, SEARCH_QUERY_EXECUTION_DEBOUNCE);
  const { contentIndexingMessage } = useContentIndexingMessage(Boolean(searchQuery), isOnline);

  const [searchResult, setSearchResult] = useState<ISearchResult | undefined>();
  const [isLoadingSearchResults, setIsLoadingSearchResults] = useState(false);
  const isMigratingSearchDatabase = globalState(
    useCallback((state) => state.isMigratingSearchDatabase, []),
  );
  const wasMigratingSearchDatabase = useRef(isMigratingSearchDatabase);

  const executeDocumentSearch = useCallback(async (searchQuery: string) => {
    setIsLoadingSearchResults(true);
    const searchResult = await documentSearchEngine.searchDocuments(
      searchQuery,
      SEARCH_DEFAULT_PAGE_SIZE,
    );
    setSearchResult(searchResult);
    setIsLoadingSearchResults(false);
  }, []);

  useEffect(() => {
    if (wasMigratingSearchDatabase.current && !isMigratingSearchDatabase) {
      // search schema finished migrating, re-fire query to refresh results.
      executeDocumentSearch(debouncedSearchQuery);
    }
    wasMigratingSearchDatabase.current = isMigratingSearchDatabase;
  }, [debouncedSearchQuery, executeDocumentSearch, isMigratingSearchDatabase]);

  useEffect(() => {
    executeDocumentSearch(debouncedSearchQuery);
  }, [debouncedSearchQuery, executeDocumentSearch]);

  // Need to use a ref here to prevent components that depend on loadMoreResultRows from unnecessarily re-rendering
  const searchResultRef = useLiveValueRef(searchResult);
  const loadMoreResultRows = useCallback(async () => {
    const searchResult = searchResultRef.current;
    if (!searchResult) {
      // TODO: throw error?
      return;
    }
    if (searchResult.rows.length >= searchResult.totalCount) {
      // nothing more to fetch
      return;
    }
    const biggerResult = await documentSearchEngine.loadMoreResultRows(searchResult);
    setSearchResult(biggerResult);
  }, [searchResultRef]);

  useEffect(() => {
    if (!searchResult || searchResult.rows.length === 0) {
      return;
    }

    updateState(
      (state) => {
        let changed = false;
        for (const resultRow of searchResult.rows) {
          if (!resultRow.parsed_doc_id) {
            logger.error('Document has no parsed_doc_id', { resultRow });
            continue;
          }
          const newTransientData: TransientDocumentData = {
            ...(state.transientDocumentsData[resultRow.doc_id] ?? createInitialTransientDocumentData()),
            searchMatches: pick(resultRow, 'content_match', 'title_match', 'author_match'),
          };
          if (!changed && !isEqual(state.transientDocumentsData[resultRow.doc_id], newTransientData)) {
            changed = true;
          }
          state.transientDocumentsData[resultRow.doc_id] = newTransientData;
        }
        if (!changed) {
          throw new CancelStateUpdate();
        }
      },
      {
        userInteraction: null,
        eventName: 'transient-data-with-search-preview-text-updated',
      },
    );
  }, [searchResult]);

  return {
    searchQuery,
    setSearchQuery,
    executedSearchQuery: debouncedSearchQuery,
    searchResult,
    isLoadingSearchResults,
    contentIndexingMessage,
    loadMoreResultRows,
  };
}
